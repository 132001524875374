import React, { useState } from "react";
interface Incoming {
  professions: string;
}
const ReadMore = (props: Incoming) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div>
      {isExpanded
        ? props.professions
        : `${props.professions.substring(0, 65)}...`}
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        style={{
          border: "none",
          background: "none",
          fontSize: "1rem",
          color: "#2e7ce0",
          outline: "none",
        }}
      >
        {isExpanded ? "Read Less" : "Read More"}
      </button>
    </div>
  );
};

export default ReadMore;
