import { Link } from "react-router-dom";
import logo from "./ctr_symboln.svg";
import ReadMore from "./readMore";
interface Incoming {
  user: {
    _id: string;
    name: string;
    pic: string | null;
    professions: { title: string }[];
    deepLinkSlug: string;
  };
}
const Modal = (props: Incoming) => {
  let professionsString = props.user.professions.map((e) => e.title).join(", ");
  return (
    <>
      <div
        className="modal fade"
        id="exampleModalLong"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="card">
                <img
                  className="card-img-top"
                  src={
                    props.user.pic
                      ? "https://connecttherelevantstaging.s3.ap-south-1.amazonaws.com/userpic/" +
                        props.user.pic
                      : "https://eagle-sensors.com/wp-content/uploads/unavailable-image.jpg"
                  }
                  alt={props.user.name ? props.user.name : ""}
                />
                <div className="card-body">
                  <div className="text-left">
                    <h6 className="fw-bolder">
                      {props.user.name ? props.user.name : ""}
                    </h6>
                    {professionsString.length > 65 ? (
                      <ReadMore professions={professionsString} />
                    ) : (
                      <span> {professionsString}</span>
                    )}
                  </div>
                  <br />{" "}
                  <Link
                    target="_blank"
                    to={`https://connecttherelevant.page.link/${props.user.deepLinkSlug}`}
                    style={{ color: "white" }}
                  >
                    <div className="connect_button">
                      {" "}
                      <img src={logo} width="30px" height="30px" alt="" />{" "}
                      Connect
                    </div>{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
