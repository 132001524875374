import React from "react";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import groovyWalkAnimation from "./animation_lkgptxqt.json";
export const Header = () => {
  return (
    <>
      <header
        id="header"
        className="fixed-top "
        style={{ background: "#2e7ce0" }}
      >
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo mr-auto">
            {" "}
            <a href="index.html">
              {/* <img src="symbol.svg" width="100" height="auto" alt="logo" /> */}
              <Lottie
                height="auto"
                width="10%"
                style={{ width: "60px" }}
                animationData={groovyWalkAnimation}
                loop={true}
              />
            </a>{" "}
          </div>
          <nav className="nav-menu d-lg-block ">
            <ul>
              <li>
                <Link to="https://connecttherelevant.com" style={{ color: "#ffffff" }}>
                  Back to Home
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};
