import SingleRow from "./SingleRow";
import SearchAndFilters from "./SearchAndFilters";
import { Header } from "./Header";
import { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import client from "../api-clients/apollo-client";
import { Footer } from "./Footer";
import { toast } from "react-toastify";
import Modal from "./Modal";

interface AllUser {
  latter: string;
  users: {
    _id: string;
    name: string;
    pic: string | null;
    professions: { title: string }[];
    deepLinkSlug: string;
  }[];
}
interface user {
  _id: string;
  name: string;
  pic: string | null;
  professions: { title: string }[];
  deepLinkSlug: string;
}
const Home = () => {
  const [loading, setloading] = useState(false);
  const [user, setUser] = useState<AllUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<user>({
    name: "",
    _id: "",
    pic: "",
    professions: [{ title: "" }],
    deepLinkSlug:""
  });
  const [search, setSearch] = useState("");
  const [professions, setprofessions] = useState<Array<string>>([]);

  useEffect(() => {
    setloading(true);
    client
      .query({
        query: gql`
          query AllUsers($name: String, $professions: [ID!]) {
            AllUser(name: $name, professions: $professions) {
              users {
                _id
                name
                pic
                professions {
                  title
                }
                deepLinkSlug
              }
              latter
            }
          }
        `,
        variables: { name: search, professions },
      })
      .then((resp) => {
        setUser(resp.data.AllUser);
        setloading(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
        setloading(false);
      });
  }, [search, professions]);

  return (
    <>
      <Header />
      <main id="main">
        <SearchAndFilters
          setprofession={setprofessions}
          setSearch={setSearch}
        />
        <Modal user={selectedUser} />

        <section className="explore_talents">
          {!loading ? (
            user.map((e) => {
              return (
                <SingleRow
                  key={e.latter}
                  latter={e.latter}
                  users={e.users}
                  setSelectedUser={setSelectedUser}
                />
              );
            })
          ) : (
            <div style={{ minHeight: "300px" }}>
              <center>
                <div
                  className="spinner-border"
                  style={{ color: "#2e7ce0" }}
                  role="status"
                >
                  <span className="visually-hidden"></span>
                </div>
              </center>
            </div>
          )}
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Home;
