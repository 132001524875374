import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Artist } from "./components/Artist";
function App() {
  return (
    <>
      <Router>
        <ToastContainer />
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/artist/:slug" Component={Artist} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
