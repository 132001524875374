import { Link } from "react-router-dom";
// import client from "../api-clients/apollo-client";
// import { gql } from "@apollo/client";
// import { toast } from "react-toastify";

interface Incoming {
  user: {
    _id: string;
    name: string;
    pic: string | null;
    professions: { title: string }[];
    deepLinkSlug: string;
  };
  setSelectedUser: (key: any) => void;
}
export const SingleCard = (props: Incoming) => {
  const getDetail = (): void => {
    props.setSelectedUser(props.user);
    // client
    //   .query({
    //     query: gql`
    //       query User($id: ID!) {
    //         User(id: $id) {
    //           name
    //           _id
    //           pic
    //           professions {
    //             title
    //           }
    //         }
    //       }
    //     `,
    //     variables: { id: props.user._id },
    //   })
    //   .then(({ data }) => {
    //     console.log(data.User);
    //   })
    //   .catch((err) => {
    //     toast.error(err.message);
    //     console.error(err);
    //   });
  };
  let professionsString = props.user?.professions
    ?.map((e) => e.title)
    .join(", ");
  return (
    <>
      {" "}
      <Link
        to={""}
        onClick={getDetail}
        data-toggle="modal"
        data-target="#exampleModalLong"
        style={{ outline: "none", border: "none" }}
      >
        <div className="col mb-5">
          <div className="card">
            <img
              className="card-img-top"
              src={
                props.user.pic
                  ? "https://connecttherelevantstaging.s3.ap-south-1.amazonaws.com/userpic/" +
                    props.user.pic
                  : "https://eagle-sensors.com/wp-content/uploads/unavailable-image.jpg"
              }
              alt="..."
            />

            <div className="card-body">
              <div className="text-left">
                <h6 className="fw-bolder">{props.user.name}</h6>
                <span>
                  {professionsString.length > 33
                    ? `${professionsString.slice(0, 30)}...`
                    : professionsString}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};
