import { SingleCard } from "./SingleCard";
interface Incoming {
  latter: string;
  users: {
    _id: string;
    name: string;
    pic: string | null;
    professions: { title: string }[];
    deepLinkSlug: string;
  }[];
  setSelectedUser: (key: any) => void;
}
const SingleRow = (props: Incoming) => {
  return (
    <>
      <div className="container px-4 px-lg-5" id={props.latter}>
        <div className="list-lable">{props.latter}</div>
        <div className="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-start">
          {props.users.length
            ? props.users.map((e) => {
                return (
                  <SingleCard
                    user={e}
                    key={e._id}
                    setSelectedUser={props.setSelectedUser}
                  />
                );
              })
            : ""}
        </div>
      </div>
      {props.latter !== "Z" ? <hr /> : ""}
    </>
  );
};

export default SingleRow;
