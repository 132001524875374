import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  useEffect(() => {
    // Update the currentYear state once when the component mounts
    setCurrentYear(new Date().getFullYear());
  }, []);

  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <div className="container d-flex align-items-center justify-content-start">
            {" "}
            <Link
              className="footer-link"
              target="_blank"
              to="https://connecttherelevant.com/terms-of-service.html"
            >
              Terms of service
            </Link>
            <Link
              className="mx-5 footer-link"
              target="_blank"
              to="https://connecttherelevant.com/privacy-policy.html"
            >
              Privacy policy
            </Link>
          </div>
        </div>
        <div className="container py-4">
          <div className="copyright">
            {currentYear} &copy; CONNECT THE RELEVANT.
          </div>
        </div>
      </footer>

      <Link to="#" className="back-to-top">
        <i className="icofont-simple-up"></i>
      </Link>
    </>
  );
};
