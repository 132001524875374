import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { Category } from "./Category";
interface Incoming {
  setprofession: (key: any) => void;
  setSearch: (key: any) => void;
}
const SearchAndFilters = (props: Incoming) => {
  const [keyword, setkeyword] = useState("");
  useEffect(() => {
    const debounceId = setTimeout(() => {
      props.setSearch(keyword);
    }, 1000);
    return () => {
      clearTimeout(debounceId);
    };
  }, [keyword, props]);
  const alphabet = [
    "#",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      // Perform the action you want to execute when the Enter key is pressed
      //   props.handleSearch(search);
      props.setSearch(keyword);
      // You can call a function here or do any other operation.
    }
  };
  return (
    <>
      {" "}
      <section
        style={{ paddingTop: "6%", paddingBottom: "2%" }}
        className="breadcrumbs"
      >
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <p>Explore Talents on CONNECT THE RELEVANT</p>
                {/* //todo appLink iNtegrate  */}
              </li>
            </ol>
          </div>
          <br />
          <div>
            <input
              className="sear saer-main"
              name="keyword"
              type="search"
              id="search"
              placeholder="Type and Press Enter to Search..."
              title="Type in a name"
              onChange={(e) => {
                setkeyword(e.target.value);
              }}
              onKeyPress={handleKeyPress}
            />
          </div>

          <div
            style={{ fontSize: "12px" }}
            className="filter-letters filters_box"
          >
            <div className="filters_box_left">
              <Category setprofession={props.setprofession} />
            </div>
            {/* style="font-size: 12px; float: left;" */}
            <div className="filters_box_right">
              <ul>
                {alphabet.map((e, index) => {
                  return (
                    <li key={e}>
                      {" "}
                      <Link
                        smooth
                        activeClass="active"
                        style={{ cursor: "pointer", color: "#2e7ce0" }}
                        to={e}
                      >
                        {e}
                      </Link>{" "}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchAndFilters;
