import React, { useEffect } from "react";



export const Artist = () => {
  useEffect(() => {
    window.location.href = "https://connecttherelevant.com";
  }, []);
  return <p>Redirecting...</p>;
};
