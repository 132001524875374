import { useEffect, useState } from "react";
import client from "../api-clients/apollo-client";
import { gql } from "@apollo/client";
import Select from "react-select";
import { toast } from "react-toastify";
interface option {
  value: string;
  label: string;
}
interface Incoming {
  setprofession: (key: any) => void;
}
export const Category = (props: Incoming) => {
  const [options, setOptions] = useState<Array<option>>([]);
  const handleChange = (selectedOption: any) => {
    props.setprofession(selectedOption.map((e: option) => e.value));
  };
  useEffect(() => {
    client
      .query({
        query: gql`
          query {
            Categories {
              parent
              parent_title
              _id
              title
              description
              order
              popular
            }
          }
        `,
      })
      .then((resp) => {
        let newOptions: option[] = [];
        for (let index = 0; index < resp.data.Categories.length; index++) {
          const element: {
            parent: string;
            parent_title: string;
            _id: string;
            title: string;
            description: string;
            order: string;
            popular: string;
          } = resp.data.Categories[index];
          newOptions.push({
            value: element._id,
            label: element.title,
          });
        }
        setOptions(newOptions);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  }, []);

  return (
    <div className="cat_dropdown">
      <span>Search by Categories</span>
      <br />
      <Select
        isClearable
        isMulti
        isSearchable
        onChange={handleChange}
        options={options}
      />
    </div>
  );
};
